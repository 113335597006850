//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
	name: 'Index',
	head: {
		title: 'Redirecting ...',
	},
	async mounted() {
		const logout = `${this.$config.apiUrl}/auth/logout?redirect=${this.$config.clubUrl}/auth/login`;

		try {
			const { authenticated, authResponse } = await this.$http.get(
				'/auth/status',
			);

			if (authenticated) {
				this.isAuthenticated(authenticated);

				this.setCurrentUser({
					name: authResponse.name,
					firstName: authResponse.firstName,
					lastName: authResponse.lastName,
					email: authResponse.email,
					picture: authResponse.avatar,
					username: authResponse.handle,
					plan: authResponse.plan,
					defaultProfile: authResponse.defaultProfile,
					profiles: authResponse.profiles,
				});

				this.setDeviceToken(authResponse.tokenId);

				window.location.href = this.$config.baseURL;
			} else {
				window.location.href = logout;
			}
		} catch (err) {
			window.location.href = logout;
		}
	},
	methods: {
		...mapActions({
			isAuthenticated: 'user/authenticated',
			setCurrentUser: 'user/setCurrentUser',
			setDeviceToken: 'app/deviceToken',
		}),
	},
};
